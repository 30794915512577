import { between } from 'polished';
import { getPassiveOptions } from './supports-passive-events';
import ReactDOMServer from 'react-dom/server';
import {  } from '../constants/analytics';
import { SELECTED_CANCER_TYPES_ANALYTICS_KEY, SELECTED_CANCER_TYPES_COUNT_ANALYTICS_KEY, DO_ADDITIONAL_GTM_DATALAYER_PUSH } from '../constants/analytics';
import { SELECTED_CANCER_TYPES_KEY, PREVIOUSLY_SELECTED_CANCER_TYPES_KEY } from '../store/storage-keys';
import { cancerTypes } from './data/cancerTypes';
import {decode} from 'html-entities';

export const createResponsiveFontRules = (
    minFontSize,
    minLineHeight,
    maxFontSize = null,
    maxLineHeight = null
) => {
    const minScreen = 320,
        maxScreen = 1032;

    let rules = `
        font-size: ${minFontSize}px;
        line-height: ${minLineHeight}px;`;

    if (maxFontSize && maxLineHeight) {
        rules += `
        @media (min-width: ${minScreen + 1}px) and (max-width: ${maxScreen}px) {
            font-size: ${between(
                minFontSize + 'px',
                maxFontSize + 'px',
                minScreen + 'px',
                maxScreen + 'px'
            )};
            line-height: ${between(
                minLineHeight + 'px',
                maxLineHeight + 'px',
                minScreen + 'px',
                maxScreen + 'px'
            )};
        }

        @media (min-width: ${maxScreen + 1}px) {
            font-size: ${maxFontSize}px;
            line-height: ${maxLineHeight}px;
        }
        `;
    }
    return rules;
};

//creates a linear equation 'calc' string for use in margin or padding within an element
export const createLocalScaleFunction = (
    minPropertyValue,
    minContainerWidth,
    maxPropertyValue,
    maxContainerWidth
) => {
    const slope = (minPropertyValue - maxPropertyValue) / (minContainerWidth - maxContainerWidth);
    const base = maxPropertyValue - slope * maxContainerWidth;
    return `calc(${base.toFixed(2)}px + ${(100 * slope).toFixed(2)}%)`;
};

const supports_intersection_observer =
    typeof window !== 'undefined' &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype;

/**
 * Helper to detect whether or not we have scrolled passed a certain element.
 * On modern browsers, we have the IntersectionObserver API. However, it
 * typically is used when an element is _visible_ within some viewport.
 * If we wanted to use it to collapse our sticky ISI, we run into an issue
 * where the element we are keying off of leaves the viewport _through the top!_
 *
 * That is, say we are keying off the ISI header. We start scrolling, and then
 * then it enters the viewport through the bottom of the screen. Cool, we collapse
 * the sticky ISI. We keep scrolling, and eventually the header leaves the viewport
 * through the top of the screen. The IntersectionObserver then detects that the
 * element is _no longer interesecting!_ But we don't care about this scenario,
 * we only care about when it enters and leaves through the _bottom_ of the screen.
 *
 * This is detectable by looking at the element's `y` coord, but is a bit
 * boilerplately to have to write each time. So, this abstracts out that logic.
 *
 * Additionally, it uses `getBoundingClientRect` and scroll / resize listeners
 * on browsers that don't support IntersectionObserver (e.g. Internet Explorer)
 * rather than using a full blown polyfill.
 *
 * However, the callback's argument won't be a real `IntersectionObserverEntry`
 * in IE and browsers that don't support it. Instead, it'll be an object with
 * a similar shape. It will **only** include `boundingClientRect`, `target`, and
 * `time` properties. All other properties will be `null`, so if your callback
 * uses things like `intersectionRect`, make sure to check for its existance.
 *
 * @example
 *     hasScrolledPassed({
 *       element: document.querySelector('.my-el'),
 *       scrolledPassedFn: (entry) => console.log('We scrolled passed!'),
 *       stillBeneathFn: (entry) => console.log('Element is beneath the viewport'),
 *     });
 *
 * @param {Object} opt
 * @param {Element} opt.element - The Element, not the selector.
 * @param {Function} opt.scrolledPassedFn - Requires at least this or `stillBeneathFn` be set. Called with one argument, an `IntersectionObserverEntry`.
 * @param {Function} opt.stillBeneathFn - Requires at least this or `scrolledPassedFn` be set. Called with one argument, an `IntersectionObserverEntry`.
 * @param {Object} [opt.intersection_observer_options]
 * @returns {Function} - Returns a "destroy" method to either disconnect the IntersectionObserver or remove the event listeners.
 */
export function hasScrolledPassed({
    element,
    scrolledPassedFn,
    stillBeneathFn,
    intersection_observer_options,
} = {}) {
    // Please ignore that I totally used the wrong `passed` everywhere. Of course it should be `past`. D'oh!
    if (!element || (!scrolledPassedFn && !stillBeneathFn)) {
        return;
    }

    let previously_scrolled_passed = false;
    let first_run = true;

    if (supports_intersection_observer) {
        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const scrolled_passed =
                    entry.isIntersecting ||
                    (!entry.isIntersecting && entry.boundingClientRect.y < 0);
                if (first_run) {
                    first_run = false;
                    if (scrolled_passed) {
                        previously_scrolled_passed = true;
                        scrolledPassedFn && scrolledPassedFn(entry);
                    } else {
                        previously_scrolled_passed = false;
                        stillBeneathFn && stillBeneathFn(entry);
                    }
                } else if (scrolled_passed && !previously_scrolled_passed) {
                    previously_scrolled_passed = true;
                    scrolledPassedFn && scrolledPassedFn(entry);
                } else if (!scrolled_passed && previously_scrolled_passed) {
                    previously_scrolled_passed = false;
                    stillBeneathFn && stillBeneathFn(entry);
                }
            });
        }, intersection_observer_options);
        observer.observe(element);

        return () => observer.disconnect();
    } else {
        // Probably Internet Explorer
        const onScrollOrResize = e => {
            const element_rect = element && element.getBoundingClientRect();
            if (!element_rect) {
                return;
            }

            // @todo support intersection_observer_options.rootMargin
            const scrolled_passed = element_rect.top < window.innerHeight;

            // @todo Make this more resilient
            const fake_entry = {
                boundingClientRect: element_rect,
                intersectionRatio: null,
                intersectionRect: null,
                isIntersecting: null,
                rootBounds: null,
                target: element,
                time: performance && performance.now && performance.now(),
            };
            if (first_run) {
                first_run = false;
                if (scrolled_passed) {
                    previously_scrolled_passed = true;
                    scrolledPassedFn && scrolledPassedFn(fake_entry);
                } else {
                    previously_scrolled_passed = false;
                    stillBeneathFn && stillBeneathFn(fake_entry);
                }
            } else if (scrolled_passed && !previously_scrolled_passed) {
                previously_scrolled_passed = true;
                scrolledPassedFn && scrolledPassedFn(fake_entry);
            } else if (!scrolled_passed && previously_scrolled_passed) {
                previously_scrolled_passed = false;
                stillBeneathFn && stillBeneathFn(fake_entry);
            }
        };
        const passive_options = getPassiveOptions();
        window.addEventListener('scroll', onScrollOrResize, passive_options);
        window.addEventListener('resize', onScrollOrResize, passive_options);

        return () => {
            window.removeEventListener('scroll', onScrollOrResize, passive_options);
            window.removeEventListener('resize', onScrollOrResize, passive_options);
        };
    }
}

/**
 * Attach an event to an element that will only fire once.
 * @param {Element} element
 * @param {String} event_type
 * @param {Function} callback
 * @returns {Function} - Returns a "destroy" function to immediately remove the event listener.
 */
export const once = (element, event_type, callback) => {
    // Purposefuly don't use an arrow function so `this` can get binded correctly
    const callbackWithRemove = function (event) {
        element.removeEventListener(event_type, callbackWithRemove);
        callback.call(this, event);
    };
    element.addEventListener(event_type, callbackWithRemove);

    // Returns a 'destroy' function that when run, immediately removes the event.
    const destroy = () => element.removeEventListener(event_type, callbackWithRemove);
    return destroy;
};

export const componentTheme = (shouldReverse, sectionThemeProp, componentThemeProp) => {
    let theme = 'dark';

    //if we set an explicit theme on a component, for some reason, use that. This should be rare.
    if (componentThemeProp) {
        return componentThemeProp;
    }

    // if there is a sectionTheme passed down from a parent section ...
    if (sectionThemeProp) {
        //reverse it, if necessary
        if (shouldReverse) {
            theme = (sectionThemeProp === 'dark') ? 'light' : 'dark';
        } else { //or just return that value
            theme = sectionThemeProp;
        }
    }

    return theme;
}

export const stringToSlug = (str) => {
    str = decode(str);
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

// How is the content coming in?
export const createMarkup = (content) => {
    let formattedContent = (typeof content !== 'string') ? ReactDOMServer.renderToStaticMarkup(content) : content;
    return { __html: formattedContent };
}

export const mapCancerTypesToKeys = (idsArr, keysMap) => {

    if (!idsArr || !idsArr.length) return '';

    // ok, we are going to sort the array so that analytics reports are as normalized as possible 
    const sortedIdArr = [...idsArr].sort((a, b) => parseInt(a) - parseInt(b));
    
    // convert the array to set of keys
    const sortedKeyArr = sortedIdArr.map(id => (keysMap[id]) ? keysMap[id].key || 'xxx' : 'xxx');

    return sortedKeyArr.join('_');

}

export const checkAndTriggerCancerTypeChange = () => {
    /**
     * While filteredCancerTypes changes on EVERY cancerType clicked within the filter menu, this analytics function gets
     * called once only when the user CLOSES the filter menu or clicks "Clear Selection" inline above the TriageList.
     */

    // if our previously selected tumor types haven't changed, then we don't need to do anything
    const currentSelectedSession = window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY);
    const prevouslySelectedSession = window.sessionStorage.getItem(PREVIOUSLY_SELECTED_CANCER_TYPES_KEY);

    // if current and previous match, the user has not made any selections
    if (currentSelectedSession === prevouslySelectedSession) return false;

    const currentSelectedArr = JSON.parse(currentSelectedSession);
    const selectedCancerTypesCount = currentSelectedArr.length;
    const newKeySlug = mapCancerTypesToKeys(currentSelectedArr, cancerTypes);

    const analyticsObj = {};
    analyticsObj[SELECTED_CANCER_TYPES_ANALYTICS_KEY] = newKeySlug;
    analyticsObj[SELECTED_CANCER_TYPES_COUNT_ANALYTICS_KEY] = selectedCancerTypesCount;

    if (window.utag) window.utag.view(analyticsObj);

    if (window.dataLayer && DO_ADDITIONAL_GTM_DATALAYER_PUSH) {
        analyticsObj.event = SELECTED_CANCER_TYPES_ANALYTICS_KEY;
        window.dataLayer.push(analyticsObj);
    } 

    window.sessionStorage.setItem(PREVIOUSLY_SELECTED_CANCER_TYPES_KEY, window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY));
}

export const getScript = (source, callback) => {
	let script = document.createElement('script');
	const prior = document.getElementsByTagName('script')[0];
	const stateChange = function stateChange(_, isAbort) {
		if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
			script.onload = null;
			script.onreadystatechange = null;
			script = undefined;

			if (!isAbort) {
				if (callback) callback();
			}
		}
	};
	script.async = 1;

	script.onload = stateChange;
	script.onreadystatechange = stateChange;

	script.src = source;
	prior.parentNode.insertBefore(script, prior);
}

/**
 * Helper function to subscribe to our redux state, but only run our handler
 * if the state has changed. ** this is only used for the Verint/chatbot store **
 * 
 * @param {Function} onChange(currentState, previousState) - Callback that runs if the next state is different than current state. Gets passed `currentState` and `previousState` as params.
 * @param {Function} [select] - Optional "selector" function to extract only part of your state. See https://github.com/reduxjs/reselect
 * @param {Object} [store] - The redux store.
 * @returns {Function} - Returns the unsubscribe function for our redux subscription.
 * 
 * @see https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 */
 export const observeStore = (onChange, select, store = window.store) => {
    let currentState;
    // console.log(store);
    function handleChange() {
        let nextState = store.getState();
        if (typeof select === 'function') {
            nextState = select(nextState);
        }

        // If you don't use a `select` function, then this will run most times the store updates because we are comparing Objects
        if (nextState !== currentState) {
            const previousState = currentState;
            currentState = nextState;
            onChange(currentState, previousState);
        }
    }

    let unsubscribe = store.subscribe(handleChange);

    // Call one time in init to save value of currentState
    handleChange();

    return unsubscribe;
};

export const getEnvironment = () => {
    let environment = '';
    if (process.env.NODE_ENV === 'development')     environment = 'localhost';   // localhost           // yarn develop
    else if (process.env.GATSBY_MIZER_BUILD)        environment = 'mizer';       // *.mzr.egplusww.com  // yarn mizer-build
    else if (!process.env.GATSBY_PRODUCTION_BUILD)  environment = 'stage';       // S3 stage            // yarn build
    else                                            environment = 'production';  // keytrudahcp.com     // yarn production-build
    return environment;
};
