export const HAS_DISMISSED_INDICATION_PREVIEW_KEY = 'mrk-hcp--dismissed-indication-preview';
export const HAS_ACKNOWLEDGED_HCP_KEY = 'mrk-hcp--acknowledged-hcp';
export const SELECTED_CANCER_TYPES_KEY = 'mrk-hcp--selected-cancer-types';
export const PREVIOUSLY_SELECTED_CANCER_TYPES_KEY = 'mrk-hcp--previously-selected-cancer-types';
export const GROUP_PARAM_KEY = 'mrk-hcp--group-parameter';
export const REFRESHED_PAGE_KEY = 'mrk-hcp--refreshed-page';

/**
 * @link https://community.cookiepro.com/s/article/UUID-1e75fb0d-bb79-2af8-cb85-f905d16f1220
 * @todo is hardcoding this cookie name safe? Is there a chance this cookie name could change?
 */
 export const ONE_TRUST_COOKIE_BANNER_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed';

 export const CHATBOT_GEOLOCATION_CACHE_KEY = 'key-hcp-location';