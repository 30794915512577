/**
 * @see https://github.com/WICG/EventListenerOptions/blob/9dcca95/explainer.md#feature-detection
 * @example `elem.addEventListener('touchstart', fn, supportsPassive ? { passive: true } : false);`
 */
export function checkSupportPassiveEvents() {
    var supportsPassive = false;
    try {
        var opts = Object.defineProperty({}, 'passive', {
            // This `getter` is only used for feature detection, so we can disable the eslint rule for this
            // eslint-disable-next-line getter-return
            get: function () {
                supportsPassive = true;
            },
        });
        window.addEventListener('testPassive', null, opts);
        window.removeEventListener('testPassive', null, opts);
    } catch (e) {}

    return supportsPassive;
}

/**
 * @type {Boolean}
 */
export const supportsPassive = checkSupportPassiveEvents();

/**
 * Returns an object `{ passive: true }` or `false` depending on whether or not the browser supports
 * passive events.
 * @example element.addEventListener('scroll', onScroll, getPassiveOptions());
 * @returns {Object|Boolean}
 */
export function getPassiveOptions() {
    return supportsPassive ? { passive: true } : false;
}
